<template>
  <g-signin-button
    v-if="googleSignInParams.client_id"
    :params="googleSignInParams"
    @success="onSignInSuccess"
    @error="onSignInError"
  >
    <button class="flex items-center rounded text-gray-700 bg-white border hover:text-gray-900 text-center w-full mx-auto">
      <span class="p-3">
        <img src="@assets/images/google.svg">
      </span>
      <span class="flex flex-col w-full mr-6">
        Continue with Google
      </span>
    </button>
  </g-signin-button>
</template>

<script>
export default {
  data () {
    return {
      /**
       * The Auth2 parameters, as seen on
       * https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams.
       * @type {Object}
       */
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_SIGN_IN_CLIENT_ID
      }
    }
  },

  methods: {
    onSignInSuccess (googleUser) {
      console.log(googleUser.getAuthResponse())
      const idToken = googleUser.getAuthResponse().id_token
      this.$emit('success', idToken)
    },
    onSignInError (error) {
      this.$emit('error', error)
    }
  }
}
</script>
