<template>
  <div class="relative w-full max-w-xl px-4 py-8 mx-auto sm:py-16">
    <SignIn />
  </div>
</template>

<script>
import SignIn from '@components/Auth/SignIn'

export default {
  components: {
    SignIn
  },

  page() {
    return {
      title: 'Sign In',
      meta: [
        { name: 'description', content: `Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` },
        { rel: 'canonical', href: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },

        { property: 'og:title', content: 'Sign In' },
        { property: 'og:site_name', content: process.env.VUE_APP_SATELLITE_NAME },
        { property: 'og:url', content: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },
        { property: 'og:image', content: `${process.env.VUE_APP_SPLASH_URL}/img/tc-logo-white.png` },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: `Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'Sign In' },
        { name: 'twitter:description', content: `Sign in to ${process.env.VUE_APP_SATELLITE_NAME}` },
        { name: 'robots', content: 'noindex' }
      ]
    }
  }
}
</script>
